<template>
    <div>
        <div class="item-row">
            <div class="plant-image"><img :src="`${require('../assets/' + item.image_url + '.webp')}`" width="150px" height="150px"></div>
            <div class="plant-content">
                <h3 class="plant-title">{{item.name}}</h3> 
                <p class="plant-description">{{item.description}}</p>
                <div class="plant-pricing">
                    <h4 class="plant-cost">Costo: {{item.cost}} {{item.cost_currency}}<br>
                        Guadagno: {{item.roi_per_second}} {{item.roi_currency}}/s</h4>
                        <button class="plant-buy-button" @click="buy(item.id)">Compra</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { bus } from '../main';
import api from '../const';

export default {
    props: {
        item:{
            type:Object
        }
    },
    methods:{
        buy(data){
            axios.post(api.url + 'inventories',{item_id:this.item.id},{
                headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                }
            }).then((response)=>{

                if(response.data.exit==1){

                    bus.$emit('refreshWallet',{
                        action: 'remove',
                        cost: api.items[this.item.id].cost,
                        currency: api.items[this.item.id].cost_currency
                    });

                    bus.$emit('refreshInventory',{data:data});
                }
            }
                


            ).catch((e)=>{
                    if(e.response.status === 401) {bus.$emit('logOut')}
                });
        }
    }
}

</script>
<style scoped>

    .item-row{
        display:flex;
        background-color:rgba(23,23,23,.2);
        padding:10px;
        border-radius:6px;
        margin-bottom:10px;
        align-items: center;
        transition:.2s;
    }
    .item-row:hover{
        transform:scale(1.05);
        background-color:rgba(253,253,253,1);
        transition: .2s;
        box-shadow: 0 10px 30px rgba(0,0,0,.3);
        
    }

    .plant-image{
        margin-right:10px;
        margin-bottom:0;
        background-color:rgb(179, 152, 126);
        border-radius:10px;
        box-shadow: inset 0 0 10px brown;
        transition:.2s;

    }
    .plant-image:hover{
        transform:scale(1.05);
        transition: .2s
        
    }
    .plant-title{
        padding:0;
        margin:0.3em 0;
        line-height:1.2em;
    }
    .plant-description{
        margin:0;
        margin-right:3em;
    }
    .plant-pricing{
        display:flex;
        align-items: center;
        justify-content:space-between;
        width:100%;
    }
    .plant-cost{
        margin-right:0;
    }
    .plant-buy-button{
        background-color:rgb(187, 140, 53);
        color:white;
        border:none;
        padding:10px;
        border-radius:6px;
        cursor:pointer;
    }
    .plant-buy-button:hover{
        color:yellow;
        background-color:rgb(162, 120, 42);
    }
</style>
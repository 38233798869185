<template>
    <div>
        <div class="field-container">
        <template v-if="!field.item_id">
            <div @drop.prevent="plant($event,1)" @dragenter.prevent @dragover.prevent class="empty-field"></div>
        </template>
        
        <template v-else>
            <div v-if="field.item_id && bloomed" class="bloomed-field gain-button" :style="{backgroundImage: `url(${require('../assets/' + imageUrl + '.webp')})`}">
                <div>
                    <button @click.stop="remove()" class="remove-plant"> X </button>
                </div>
                <div  @click.stop="gain(field.id)" class="gain-amount"><b>{{currentGain}}</b> <br><small>{{currency}}</small></div>

                
            </div>

            <div v-if="field.item_id && !bloomed" class="blooming-field"><div><small>Sboccerà {{field.time_to_bloom | willBloomIn}}...</small></div></div>
             <!--div v-if="message" class="message">{{message}}</div-->
        </template>
     </div>
    </div>

</template>

<script>
    import axios from "axios";
import api from "../const";
import { bus } from "../main";
import moment from 'moment';

    export default{
       
        mounted(){
            this.hasBloomed();

            if(this.bloomed){
                this.getSeconds();
            }
            setInterval(()=>{this.hasBloomed(); if(this.bloomed){this.seconds+=5;}},5000);
        },
        data(){
            return{
                seconds: 0,
                message: null,
                bloomed: false,
            }
        },
        props:{
            field: {
            type: Object
            },
        },
        filters:{
            plantName(value){
                return api.items[value].name;
            },
            willBloomIn(value){
                let result = moment(value).from(api.time);
                return result;
            },

        },
        computed:{
            currentGain(){
                let result;
                const max_gain = api.items[this.field.item_id].max_gain;
                if (result > max_gain){
                    result = max_gain;
                }else{
                    result = (this.seconds * api.items[this.field.item_id].roi_per_second).toFixed(2);
                }
                return result;
            },
            currency(){
                return api.items[this.field.item_id].roi_currency;
            },
            imageUrl(){
                return api.items[this.field.item_id].image_url;
            }
        },
        methods:{
            plant(data){
                let plant = data.dataTransfer.getData('item_id');
                axios.post(api.url + 'fields/' + this.field.id, {
                    _method:"PATCH",
                     action: "plant",
                     item_id: plant,
                },{
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                }}).then((response)=>{
                    if(response.data.exit === 1){
                        bus.$emit('refreshFields',{});
                        bus.$emit('refreshInventory',{});
                    }else{
                        this.message = 'Impossibile esegure l\'azione';
                    }
                }).catch((e)=>{
                    if(e.response.status == 401) bus.$emit('logOut');
                });
            },
            remove(){

                this.bloomed = false;
                this.field.item_id = null;
                this.seconds = 0;
                
                axios.post(api.url + 'fields/' + this.field.id, {
                    _method:"PATCH",
                     action: "remove",
                },{
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                }}).then((response)=>{
                    if(response.data.exit === 1){

                        bus.$emit('refreshInventory',{});
                        bus.$emit('refreshFields',{});
                    }else{
                        this.message = 'Impossibile esegure l\'azione';
                    }
                }).catch((e)=>{
                    if(e.response.status == 401) bus.$emit('logOut');
                });
            },


            gain(field){
                axios.post(api.url + "fields/" + field, {
                    _method:"PATCH",  
                    action:'gain'
                },{headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                }}).then((response)=>{
                    this.seconds = 0;
                    this.message = response.data.message + " " + response.data.amount.toFixed(2) + " " + response.data.currency;
                    bus.$emit('refreshWallet',{
                        action: 'gain',
                        gained: response.data.amount,
                        currency: response.data.currency,
                    });
                    
                }).catch((e)=>{
                    if(e.response.status == 401) bus.$emit('logOut');
                });
            },
            getSeconds(){
                let fieldSeconds;
                if(this.field.gain_time){
                    fieldSeconds =   moment(this.field.gain_time);
                }else{
                    fieldSeconds =   moment(this.field.time_to_bloom);
                }
                this.seconds = api.time.diff(fieldSeconds, 'seconds');
            },

            hasBloomed(){
                let result = moment(this.field.time_to_bloom) < moment(api.time); 
                this.bloomed = result;
            },
        },
    }
</script>
<style>
    .empty-field{
        display:flex;
        vertical-align: center;
        height:120px;
        box-sizing: border-box;
        padding:10px;
        border-radius:10px;
        border: 1px dashed gray;
        text-align:center;
        background-image: url('../assets/00A_campo_incolto.webp');
        background-size:cover;
    }
    .blooming-field{
        display:flex;
        align-items: baseline;
        height:120px;
        box-sizing: border-box;
        padding:10px;
        border-radius:10px;
        text-align:center;
        color:white;
        background-image: url('../assets/00B_campo_incolto_crescendo_001.webp');
        background-size:cover;
    }
    .bloomed-field{
        background-size:cover;
    
    }

    .field-container{
        height:100%;
        padding:0px;
        box-sizing:border-box;
        
    }
    .message{
        font-size:14px;
    }
    .field-header{
        display:block;
        align-content: flex-end;
        background-color:green;
    }
    .remove-plant{
        display:block;
        background-color:red;
        border:none;
        border-radius:6px;
        color:white;
        cursor:pointer;
        opacity: 0;
        transition: .2s;
    }
    .gain-button:hover .remove-plant{
        opacity: 1;
        transition: 1s;
    }
    .gain-button{
        display:flex;
        flex-wrap: wrap;
        justify-content: end;
        width:100%;
        height:100%;
        border-radius:6px;
        border:0px solid green;
        cursor: pointer;
        color:white;
        font-weight: bold;

    }
    .gain-button:hover{
        background-color: rgba(255,255,255,.3);
    }
    .gain-amount{
        font-size:1.3em;
        width:100%;
        text-align: center;
        text-shadow:  0 0 4px black;
    }
</style>
<template>
    <div class="form-container">
        <p>New to the game? Register here:</p>
        <div class="register-form">
            <div class="register-field">
            <label>
            Name
            </label>
            <input type="text" v-model="name">
            </div>
            <div class="register-field">
            <label>
            Email
            </label>
            <input type="email" v-model="email">
        </div>
        <div class="register-field">
            <label>
            Password
            </label>
            <input type="password" v-model="password">
       
        </div>
        <div class="register-field">
        <button @click="login()">Enter</button></div>
        </div>
        <div><p v-if='message'>{{message}}</p></div>
    </div>
</template>
<script>
    import axios from 'axios';
    import api from '../const.js';
    import {bus} from '../main.js';
    export default {
        data(){
            return{
                name:"",
                email:"",
                password:"",
                message: null,
            }
        },
        methods: {
            login(){
                axios.post(api.url + 'register', {
                    name: this.name,
                    email: this.email,
                    password:this.password,
                    headers: { "Content-Type": "multipart/form-data" }
                }).then((response)=>{
                        bus.$emit('register',response.data);
                }).catch((e)=>{
                    this.message = e.response.data.message;
                });
            }
        }
    }
</script>
<style>
    .form-container{
        max-width:600px;
        margin:30px auto;
        background-color: #eee;
        padding:20px;
        border-radius:6px;

    }
    .register-form{
        padding:10px;
    }
    .register-field{
        padding-top:10px;
    }
    label{
        display:block;
    }
</style>
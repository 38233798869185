<template>
    <div>
        <article class="container">
            <div>
                <h1 class="shop-title">Negozio</h1>
                <p>Qui puoi comprare i vari oggetti. Gli oggetti ti permettono di guadagnare, e così potrai comprarne altri! Dai sfogo alla tua fantasia!</p>
                <div class="shop-items">
                    <div v-for="item,x in items" :key="x">
                    <ShopItem :item="item"></ShopItem>
                    </div>
                </div>
                
            </div>
        </article>
    </div>
</template>
<script>

import api from "../const"
import ShopItem from './ShopItem.vue'

export default{

    components:{
        ShopItem,
    },
    data(){
        return {
            items:{},
        }
    },
    mounted(){
        this.items = api.items;
    },
}

</script>
<style scoped>
    .shop-title{
        margin:0;
    }
    article{
        width:calc(70% - 10px);
        margin-left:10px;
        float:left;
        background-color:green;
    }
    .container{
        display:flex;
        flex-wrap: wrap;
        background-color:rgb(209, 255, 251);
        margin-bottom:10px;
        border-radius:15px;
        padding:9px;
        box-sizing: border-box;
    }
    .shop-items{
    }

</style>
<template>
    <div>
        <div class="menu-container">
            <ul class="game-menu">
                <li @click="changeScreen('Field')">Piantagioni</li>
                <li @click="changeScreen('Shop')">Negozio</li>
                <li @click="changeScreen('Account')">Account</li>
            </ul>
        </div>
        
    </div>
</template>
<script>
import { bus } from '../main';

export default{
    data(){
        return{

        }
    },
    methods:{
        changeScreen(page){
            bus.$emit('changePage', {page:page});
        }
    }
}
</script>
<style>
    .menu-container{
        margin-bottom:10px;
        border-radius:10px;
        background-color:bisque;
        padding:20px;
        display:block;
    }
    .game-menu{
        padding:0;
        margin-top:0;
    }
    .game-menu li {
        padding:10px;
        background-color:orange;
        border-radius:6px;
        margin-bottom:5px;
        list-style: none;
    }
    .game-menu li:hover{
        background-color:yellow;
        cursor:pointer;
    }
</style>
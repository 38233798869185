<template>
    <div>
        <div class='sidebar'>
            <div class="menu">
                <GameMenu></GameMenu>
            </div>
            <div class="wallet">

                <Wallet></Wallet>
                
            </div>
            <div class="inventory">
                <Inventory></Inventory>
            </div>
        </div>
    </div>
</template>
<script>
    import Inventory from './Inventory.vue';
    import Wallet from './Wallet.vue';
    import GameMenu from './GameMenu.vue';
    export default{
        components:{
            Inventory,
            Wallet,
            GameMenu
        }
    }
</script>

<style scoped>
    .sidebar{
        width:calc(30% - 20px);
        float:right;
        margin:0 10px;


    }
    .sidebar ul li {
        cursor:pointer;
        list-style: none;
    }
</style>
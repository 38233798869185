<template>
  <div id="app">
    <LoginScreen v-if="this.api.token == null" ></LoginScreen>
    <RegisterScreen v-if="this.api.token == null"></RegisterScreen>
    <GameScreen v-if="this.api.token != null && currentPage == 'GameScreen'"></GameScreen>
    <ShopScreen v-if="currentPage == 'ShopScreen'"></ShopScreen>
  </div>
</template>

<script>
import axios from 'axios';
import LoginScreen from './components/LoginScreen.vue';
import GameScreen from './components/GameScreen.vue';
import api from './const'
import {bus} from './main.js';
import RegisterScreen from './components/RegisterScreen.vue';
import moment from 'moment';
export default {
 
  name: 'App',
  components: {
    LoginScreen,
    GameScreen,
    RegisterScreen
},

  created(){
            //controlla se c'è un token
            api.token = localStorage.getItem('token');
            api.user_id = localStorage.getItem('user_id');
            //controlla se il token è nullo, se lo è lo annulla definitivamente.
            if(api.token == "null"){
              api.token = null;
            }
            if(api.user_id == "null"){
              api.user_id = null;
            }

            bus.$on('logIn', (data) => {
                api.token = data.token;
                api.user_id = data.user_id;
                localStorage.token = data.token;
                localStorage.user_id = data.user_id;
             });

             bus.$on('register', (data) => {
                api.token = data.token;
                api.user_id = data.user_id;
                localStorage.token = data.token;
                localStorage.user_id = data.user_id;
             });

            bus.$on('logOut', () => {
                api.token = null;
                localStorage.token = null;
                api.user_id = null;
                localStorage.user_id = null;
            });

         

            api.items = {};
            axios.get(api.url + "items").then((response)=>{

              response.data.forEach((item)=>{
                api.items[item.id]  = item;
              });
            });

            api.fprices = {};
            axios.get(api.url + "fields-price").then((response)=>{

              response.data.forEach((fprice)=>{
                api.fprices[fprice.id]  = fprice;
              });
            });

            axios.get(api.url + "time").then((response)=>{
              //api.time = new Date(response.data.time.date);
              api.time = new moment(response.data.time.date);
              //setInterval(()=>{api.time.setSeconds(api.time.getSeconds() + 1);}, 1000);
              setInterval(()=>{api.time.add(1,'seconds')},1000);
            });
            


        },
  data(){
    return {
      api: api,
      currentPage: 'GameScreen'
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;500&display=swap');

  *{
  font-family: 'Figtree', sans-serif;
  }
body{
  padding:0;
  margin:0;
  background-image:url("./assets/background.webp");

}
#app{
  max-width:1300px;
  margin: 0 auto;
  box-shadow: 10px 10px 40px rgba(0,0,0,.3);
  float:none;
  background-color:white;
}
</style>

<template>
    <div>
        <article class="container">
            <div v-for="field,id in this.fields" v-bind:key="id" class="single-field-pre-container">

                <FieldSingle :field="field" class="single-field"></FieldSingle>
                
            </div>
                <div>
                    <div class="single-field">
                        <div class="field-container">
                        <button @click="buyField()" class="buy-field">Compra<br> <b>{{newFieldPrice}} {{newFieldCurrency}}</b></button>
                        <div v-if="message" class="field-error-message">{{message}}Ti servono {{needed}}.</div>
                        </div>
                    </div>
                </div>
        </article>
    </div>
</template>

<script>
import axios from 'axios';
import api from "../const.js";
import {bus} from "../main.js";
import FieldSingle from "./GameScreenFieldSingle.vue";

    export default{

        components: {FieldSingle},
        mounted(){
            this.refreshFields();
            bus.$on('refreshFields',()=>{
                this.refreshFields();
            })
        },
        computed: {
            newFieldPrice(){
                let result;
                 try { 
                    result = api.fprices[this.fields.length+1].amount
                }catch{
                    result = 0;
                 }
                 return result;
            },
            newFieldCurrency(){
                let result;
                try{
                    result = api.fprices[this.fields.length+1].currency;
                }catch{
                    result = 'bills';
                }
                return result;
            }
        },

        data(){
           return{
             fields: [],
             selectedField: null,
             message:null,
             needed: null,
           }
        },

        methods:{
            refreshFields(){
                axios.get(api.url + 'fields', {
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                    }
                }).then((response)=>{
                    this.fields = response.data
                }).catch((e)=>{
                    if(e.response.status === 401) {bus.$emit('logOut')}
                });
            },
            buyField(){
                axios.post(api.url + 'fields',{}, {
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                    }
                }).then((response)=>{
                    if(response.data.exit == 1){
                        this.refreshFields();
                        bus.$emit('refreshWallet',{
                            action:'remove',
                            currency: response.data.currency,
                            cost: response.data.cost,
                        });
                        this.message = null;
                        this.needed = null;
                    }else{
                        this.message = response.data.message;
                        this.needed = `${(response.data.cost - response.data.money[response.data.currency]).toFixed(2)} ${response.data.currency}`
                    }
                }).catch((e)=>{
                    if(e.response.status === 401) {bus.$emit('logOut')}
                });
            }
        }

    }
</script>

<style scoped>
    article{
        width:calc(70% - 10px);
        min-height:50vh;
        margin-left:10px;
        margin-bottom:10px;
        float:left;
        background-color:green;
    }
    .container{
        display:flex;
        align-content:flex-start;
        flex-wrap: wrap;
        background-color:rgb(181, 146, 121);
        border-radius:15px;
    }
    .single-field{
        height:120px;
        /*background-color:rgb(192, 167, 141);*/
        border-radius:10px;
        margin:9px;
        width:120px;
        
    }
    .field-container{
        padding:10px;
    }
    .buy-field {
        background-color:greenyellow;
        border:none;
        padding:4px;
        margin-bottom:4px;
        width:100%;
        box-sizing: border-box;
        height:100%;
        border-radius:6px;
        cursor:pointer;
        transition:.2s;
        box-shadow: 0 0 0px rgb(255, 255,255);
    }
    .buy-field:hover{
        background-color:rgb(208, 255, 137);
        box-shadow: 0 0 30px rgb(208,255,137);
        transition:.2s
    }
    .field-error-message{
        font-size:16px;
        position:fixed;
        bottom:0;
        left:0;
        padding:10px;
        background-color:red;
        width:100%;
        color:white;
    }
</style>
<template>
<div>
    <div class="menu-container">   
        <h2>Il tuo portafoglio</h2>
        <div class="currency">{{wallet.bills}} bills</div>
        <div class="currency">{{wallet.gems}} gems</div>
        <div class="currency">{{wallet.stars}} stars</div>
        <div class="currency">{{wallet.euro}} euro</div>
    </div>
</div>
</template>
<script>

import axios from 'axios';
import { bus } from '../main';
import api from '../const';

    export default{
    mounted(){

    this.refreshWallet();

    bus.$on('refreshWallet', (data)=>{
        //this.refreshWallet();
        if(data.action == 'gain'){
            this.wallet[data.currency] = parseFloat(this.wallet[data.currency]) + data.gained;
            this.wallet[data.currency] = this.wallet[data.currency].toFixed(2);
        }
        if(data.action == 'remove'){
            this.wallet[data.currency] = parseFloat(this.wallet[data.currency]) - parseFloat(data.cost);
            this.wallet[data.currency] = this.wallet[data.currency].toFixed(2);
        }
    })
    },


    data(){
        return{
            wallet:{
            },
        }
    },

    methods:{
    refreshWallet(){
        axios.post(api.url + 'wallet',{}, {
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                    }
                }).then((response)=>{
                    this.wallet = response.data;
                }).catch((e)=>{
                    if(e.response.status == 401) bus.$emit('logOut');
                });
    }
    }
}
</script>
<style>

    h2{
        margin:0;
        margin-bottom:10px;
    }
</style>
<template>
<div>
    <div class="form-container">
        <h1>Welcome to Relaxing Game!</h1>
        <p>Use this form to login.</p>
        <div class="register-form">
            <div class="register-field">
        <label>
            Email
        </label>
            <input type="text" v-model="email">
        </div>
        <div class="register-field">
            <label>
            Password
        </label>
            <input type="password" v-model="password">
        
        </div>
        <div class="register-field">
            <button @click="login()">Enter</button>
        </div>

        <div><p v-if='message'>{{message}}</p></div>
        </div>
        

    </div>
</div>
</template>
<script>
    import axios from 'axios';
    import api from '../const.js';
    import {bus} from '../main.js';
    export default {
        data(){
            return{
                email:"",
                password:"",
                message: null,
            }
        },
        methods: {
            login(){
                axios.post(api.url + 'login', {
                    email: this.email,
                    password:this.password,
                    headers: { "Content-Type": "multipart/form-data" }
                }).then((response)=>{
                        bus.$emit('logIn',response.data);
                }).catch((e)=>{
                    this.message = e.response.data.message;
                });
            }
        }
    }
</script>
<style scoped>
    .container{
        text-align:center;
    }
</style>
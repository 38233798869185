<template>
    <div>
        <div class="menu-container">
            <div v-for="inventory,index in inventories" :key="index" draggable @dragstart="onDrag($event,inventory)">
                <template v-if="inventory.amount > 0"> {{inventory.item_id | plantName}}: {{inventory.amount}} </template>
            </div>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios';
import api from '../const';
import { bus } from '../main';
    export default{
        mounted(){
            this.refreshInventory();
            bus.$on('refreshInventory', ()=>{
                this.refreshInventory(); 
            })
        },
        data(){
            return {
                inventories:[]
            }
        },
        methods:{
            onDrag(evt,inventory){
                evt.dataTransfer.dropEffect = 'drop';
                evt.dataTransfer.effectAllowed = 'drop';
                evt.dataTransfer.setData('item_id',inventory.item_id);
            },
            refreshInventory(){
                axios.get(api.url + "inventories", {
                headers:{
                    Authorization: "Bearer " + api.token,
                    Accept: "application/json",

                }
                }).then((response)=>{
                    this.inventories = response.data;
                });

            }
        },
        filters:{
            plantName(value){
                return api.items[value].name;
            }
        }

    }
</script>
<style>

</style>
<template>
<div>
    <div class="header">
        <div class="header-element">Il gioco del Relax</div>
        <div class="header-element"><button @click="logout();">Logout</button></div>
    </div>

    <div>
        <div class="gameScreen">
            <KeepAlive>
                <Field v-if="currentPage == 'Field'"></Field>
            </KeepAlive>
            <KeepAlive>
                <Shop v-if="currentPage == 'Shop'"></Shop>
            </KeepAlive>
        </div>
        <aside >
            <Sidebar></Sidebar>
        </aside>
        <div class="footer">Un gioco di missionerelax.it</div>
    </div>

</div>
</template>
<script>
import {bus} from '../main.js';
import api from '../const.js';
import axios from 'axios';
import Sidebar from './GameScreenSidebar.vue';
import Field from './GameScreenField.vue';
import Shop from './ShopScreen.vue';


    export default{
        props:["data"],
        components: {
            Sidebar,
            Field,
            Shop,
        },
        mounted(){
            bus.$on('changePage',(data)=>{
              this.currentPage = data.page;
            });
            this.name = api.user_id;
        },
        methods:{
            logout(){

                axios.post(api.url + 'logout', {}, {
                    headers:{
                    Authorization: 'Bearer ' + api.token,
                    Accept: "application/json",
                    }
                }).catch((e)=>{
                    if(e.response.status === 401) {bus.$emit('logOut')}
                });

                bus.$emit('logOut');
            }
        },
        data(){
            return{
                currentPage: 'Field',
                username: 'Username',
            }
        }
    }

</script>
<style scoped>
    .header{
        display: flex;
        align-items: center;
        justify-content:space-between;
        background-color:rgb(123, 204, 123);
        margin-bottom:10px;
        padding:10px;
    }
    .header-element{
        padding:3px;
        display:block;
        color:white;
    }
    .header-element button{
        background-color:green;
        color:white;
        border: none;
        border-radius:6px;
        padding:10px;
        min-width:120px;
        cursor:pointer;
    }
    .footer{
        float:none;
        clear:both;
        width:100%;
        padding:20px;
        background-color:rgb(123, 204, 123);
        margin-top:20px;
        box-sizing: border-box;
        color:white;
    }
</style>